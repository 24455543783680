.CollectionPublicPassword {
    margin-top: 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
}

.CollectionPublicPassword__toggle {
    margin-right: 5px;
}

.CollectionPublicPassword label {
    color: #fff !important;
}
