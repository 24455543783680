.media-items {
    position: relative;
    list-style: none;
}

.media-items-columns-1 .media-item-wrapper-outer {
    width: 100%;
}
.media-items-columns-2 .media-item-wrapper-outer {
    width: 50%;
}
.media-items-columns-3 .media-item-wrapper-outer {
    width: 33%;
}
.media-items-columns-4 .media-item-wrapper-outer {
    width: 25%;
}
.media-items-columns-5 .media-item-wrapper-outer {
    width: 20%;
}
.media-items-columns-6 .media-item-wrapper-outer {
    width: 16.6%;
}
