.layout {
}

.layout__navigation {
}

.layout__content {
}

.layout__side {
    display: flex;
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
}

.layout__side.layout__side--collapsed {
    bottom: auto;
    z-index: 10;
}

.layout__side::-webkit-scrollbar {
    width: 0;
}

.layout__main {
}
