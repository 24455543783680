.remove {
    //background: #000;
    //color: #fff;
}

.add {
    text-decoration-line: underline;
    text-decoration-width: 0.5px;
    text-decoration-style: dashed;
}
